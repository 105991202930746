import { ChatLauncher } from './ChatLauncher';
import { Config } from './config/config';
import { DeviceHelper } from './DeviceHelper';
import { Disposable } from './Disposable';
import { Modal } from './Modal';
import { Display, LauncherSettings, SDKSettings } from './Settings';

const NATIVECHAT_CONTAINER_ID = 'native-chat-container';

export class ModalChat implements Disposable {
  private modal?: Modal;
  private tabOnMobileDevice?: Window;
  private launcher?: ChatLauncher;

  constructor(sdkSettings: SDKSettings, launcherSettings?: LauncherSettings) {
    this.modal = new Modal(sdkSettings.id, sdkSettings.origin, sdkSettings.chat);

    const isMobile = DeviceHelper.isMobile();

    this.launcher = new ChatLauncher(
      sdkSettings.id,
      () => this._launcherOpenHandler(isMobile, sdkSettings),
      () => this._launcherCloseHandler(isMobile),
      launcherSettings);
  }

  public dispose() {
    if (this.launcher) {
      this.launcher.dispose();
    }

    this.launcher = undefined;

    if (this.modal) {
      this.modal.dispose();
    }

    this.modal = undefined;
  }

  private _launcherOpenHandler(isMobile: boolean, sdkSettings: SDKSettings) {
    if (!isMobile) {
      return this.modal && this.modal.open();
    }

    const settings = JSON.parse(JSON.stringify(sdkSettings));

    settings.display = {
      containerId: NATIVECHAT_CONTAINER_ID,
      mode: Display.Inline,
    };

    const blob = new Blob([
      `<html>
        <head>
          <title>NativeChat</title>
          <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1">
          <script src="${Config.nativeChatScriptUrl}"></script>
          <link href="${Config.nativeChatCssUrl}" rel="stylesheet" type="text/css">
          <style>
          html, body, { height: 100%; }
          body { margin: 0; padding: 0; height: 100%; }
          #${NATIVECHAT_CONTAINER_ID} { width: 100%; height: 100%; }
          #${NATIVECHAT_CONTAINER_ID} iframe { width: 100%; height: 100%; border: none; }
          </style>
        </head>
        <body>
          <div id="${NATIVECHAT_CONTAINER_ID}"></div>
        </body>
        <script>
        const nativechatSettings = ${JSON.stringify(settings)};
        window.NativeChat.load(nativechatSettings);
        </script>
      </html>`,
    ], { type: 'text/html' });

    if (DeviceHelper.isChromeiOS()) {
      const reader = new FileReader();

      reader.onload = (_) => {
        this.tabOnMobileDevice = window.open('', '_blank') || undefined;

        if (this.tabOnMobileDevice) {
          this.tabOnMobileDevice!.location.href = (reader.result as string);
        }
      };

      return reader.readAsDataURL(blob);
    }

    const containerURL = window.URL.createObjectURL(blob);

    this.tabOnMobileDevice = window.open(containerURL, '_blank') || undefined;
  }

  private _launcherCloseHandler(isMobile: boolean) {
    if (!isMobile) {
      return this.modal && this.modal.close();
    }

    if (this.tabOnMobileDevice) {
      this.tabOnMobileDevice.close();
    }
  }
}
