import { ChatIFrame } from './ChatIFrame';
import { ElementIds } from './Constants';
import { Disposable } from './Disposable';
import { ChatSettings } from './Settings';

export class Modal implements Disposable {
  private chat?: ChatIFrame;
  private container?: HTMLDivElement;

  private id: string;
  private origin: string;
  private chatSettings: ChatSettings;
  private isOpenField: boolean;

  constructor(id: string, origin: string, chatSettings: ChatSettings) {
    this.id = id;
    this.origin = origin;
    this.chatSettings = chatSettings;

    this.isOpenField = false;

    this.container = document.createElement('div');
    this.container.className = ElementIds.launcherModal;
    this.chat = new ChatIFrame(this.id, this.origin, this.chatSettings, this.container);
  }

  public get isOpen(): boolean {
    return this.isOpenField;
  }

  public dispose() {
    this.close();

    if (this.chat) {
      this.chat.dispose();
    }

    this.chat = undefined;
    this.container = undefined;
  }

  public open() {
    if (this.container && !this.isOpenField) {
      document.body.appendChild(this.container);
      this.isOpenField = true;
    }
  }

  public close() {
    if (this.container && this.isOpenField) {
      document.body.removeChild(this.container);
      this.isOpenField = false;
    }
  }
}
